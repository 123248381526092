import { Controller as BaseController } from "@hotwired/stimulus"
import RequestController from "@components/utilities/request_controller"

export class Controller extends BaseController {

  navigate(event) {
    event.preventDefault();

    const element = this.element;
    const url = element.getAttribute('data-href');
    const remote = element.getAttribute('data-remote') === 'true';
    const type = element.getAttribute('data-type') || 'GET';
    const turbo = element.getAttribute('data-turbo') === 'true';
    const turbo_frame = element.getAttribute('data-turbo-frame');

    if (url) {
      if (remote) {
        $.ajax({
          type: type.toUpperCase(),
          url: url,
          dataType: 'script',
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          }
        });
      } else if (turbo) {
        const request = new RequestController();
        request.fetchTurboFrame({ params: { url: url, turbo_frame: turbo_frame } })
      } else {
        window.location.href = url;
      }
    }
  }
}