import { Controller as BaseController } from "@hotwired/stimulus";
import RequestController from "../../utilities/request_controller";

export class Controller extends BaseController {
  connect() {
    if (this.element.dataset.connected === 'true') return;
    this.element.dataset.connected = 'true';
    this.navigationHistory = [];
    this.element.addEventListener('turbo:before-frame-render', this.handleFrameRender.bind(this));
    this.element.addEventListener('turbo:submit-end', this.handleSubmitEnd.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-end', this.handleSubmitEnd.bind(this));
  }

  handleSubmitEnd(event) {
    const response = event.detail.fetchResponse;

    if (response.succeeded) {
      const element = document.getElementById('toast-success');
      this.closeModalAfterSubmit();
    }
  }

  fetchForm(event) {
    const path = event.target.value;

    if (!path) {
      document.getElementById("reassign_form").innerHTML = "";
      return;
    }

    const requester = new RequestController;
    
    requester.fetchTurboStream({
      params: {
        url: path,
        method: 'GET'
      }
    }).catch((error) => console.error("Error fetching Turbo Stream:", error));
  }


  addNavigationHistory(content) {
    this.navigationHistory.push(content);
  }

  goBack() {

    if (this.navigationHistory.length > 0) {
      const previousState = this.navigationHistory[this.navigationHistory.length - 1];
      document.getElementById("reassign_form").innerHTML = previousState;
      this.navigationHistory.pop();
    } else {
      console.warn("No navigation history to go back to.");
    }
  }

  handleFrameRender() {
    this.addNavigationHistory(this.element.querySelector('turbo-frame').innerHTML);
  }
  
  closeModalAfterSubmit() {
    this.removeModal();
    //need to wait 1 second before dom recognizes the change
    setTimeout(() => {
      if (typeof ReassignImportedFiles !== 'undefined') {
        ReassignImportedFiles.init();
      }
    }, 1000);
  }

  removeModal() {
    const modalIds = [
        "move-consult-document-modal-ava-modal-container",
        "move-other-document-modal-ava-modal-container",
        "move-result-modal-ava-modal-container",
    ];

    modalIds.forEach(id => {
        const elem = document.getElementById(id);
        if (elem && elem.parentNode) {
          elem.parentNode.removeChild(elem);
        }
    });

    const dataModal = document.getElementById('data-modal')
    if (dataModal) {
      dataModal.style.display = 'none';
      dataModal.parentElement.style.display = 'none';
    }
  }
}