import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['combobox'];

  connect() {
    MacroContextMenu.init();
    DatePicker.init();
    Tooltipster.init();
    ResetFoundation.init();
    ConsultNote.init();
    StandardJs.init();
    ReassignImportedFiles.init();
    TextGenerator.init($('.consult-data-window'));  
  }

  comboboxTargetConnected() {
    setTimeout(() => {
      Combobox.init();
    }, 50);
  }

}