import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  // By default this controller will not reload the content on subsequent clicks
  // To override this, set data-utilities--request-force="true"

  // This allows us to have an event show/hide an element but only load the
  // content once. If the content should be reloaded then we can set the 
  // data-utilities--request-forcee="true" on the element
  static values = { loaded: Boolean, force: Boolean }

  connect() {
    this.loadedValue = false;
    this.force = this.force || false;
  }

  fetchScript({ params }) {
    if (!this.loadedValue || this.force) {
      $.ajax({
        url: `${params.url}`,  
        method: 'GET',
        dataType: 'script',
        success: response => {
          this.loadedValue = true;
        }
      });
    }
  }

  fetchTurboStream({ params }, successCallback) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${params.url}`,
        method: `${params.method || 'GET'}`,
        headers: {
          "Accept": "text/vnd.turbo-stream.html",
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
        },
        success: function(response) {
          // Manually handle the Turbo Stream response
          Turbo.cache.clear();
          Turbo.renderStreamMessage(response);
          successCallback && successCallback();
          resolve(response);
        },
        error: function(xhr, status, error) {
          reject(error);
        }
      });
    });
  }

  fetchTurboFrame({ params }) {
    if (!this.loadedValue || this.force) {
      const frame = document.getElementById(params.turbo_frame)
      if (frame) {
        frame.src = params.url; // Set the src of the Turbo Frame
        this.loadedValue = true;
      }
    }
  }

  submitTurboForm(event) {
    event.preventDefault();
    const form = event.target;
    const url = form.action;
    const formData = new FormData(form);
  
    const searchParams = new URLSearchParams(formData);
    const queryString = searchParams.toString();
    const requestUrl = `${url}?${queryString}`;
  
    fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }

  fetchJson({ url, method = 'GET', data = null }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        method: method,
        data: data,
        dataType: 'json',
        success: response => {
          resolve(response);
        },
        error: (xhr, status, error) => {
          reject(error);
        }
      });
    });
  }
}
