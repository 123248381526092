// This controller is loaded when the shared-ui--modal-ui--modal component is loaded
// and the view component is then moved to the body of the document in order to be
// properly positioned with a backdrop.

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {

  connect() {
    this.moveModal()
    DatePicker.init()
    Combobox.init()
  }

  closeModal() {
    this.removeModal()
  }

  moveModal() {
    if (this.element.parentNode !== document.body) {
      document.body.appendChild(this.element);
    }
  }

  removeModal() {
    if (this.element.parentNode === document.body) {
      document.body.removeChild(this.element);
    }
  }

  // for some reason esc is being intercepted elsewhere so
  // this doesn't work
  // getKeyEvent(event) {
  //   if (event.key === "Escape") {
  //     this.closeModal()
  //   }
  // }
}
