import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  connect() {
    this.emptyConsultContainer()
  }

  // empties the consult data window so that consults can be
  // edited in the modal without targeting content underneath
  emptyConsultContainer() {
    document.querySelector(".consult-data-window").innerHTML = ""
  }
}